import anime from 'animejs/lib/anime.es.js';
import './styles/app.scss';
import {ProjectBoard} from './scripts/project-board';

import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

// automatically add all images to the manifest.json
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

function scrollToBoardingPass() {
    const matches = /^#boarding-pass-(.+)$/.exec(window.location.hash);

    if (matches === null) {
        return;
    }

    const el = document.querySelector('[data-boarding-pass]');

    if (!el) {
        return;
    }

    const target = el.querySelector(`[data-boarding-pass-content="${matches[1]}"]`);

    if (!target) {
        return;
    }

    let spotlight = document.querySelector('.spotlight');

    if (!spotlight) {
        spotlight = document.createElement('div');
        spotlight.className = 'spotlight';
        document.body.appendChild(spotlight);
    }

    function initSpotlight() {
        const isDesktop = window.matchMedia('(min-width: 768px)').matches;
        const size = isDesktop ? 200 : 100;
        const border = isDesktop ? 40 : 20;

        const startX = el.getBoundingClientRect().left + (size / 2);
        const startY = el.getBoundingClientRect().top + (size / 2);
        const targetX = target.getBoundingClientRect().left + (target.offsetWidth / 4); // content inside element is only at half width
        const targetY = target.getBoundingClientRect().top + (target.offsetHeight / 2);

        spotlight.style.backgroundImage = `radial-gradient(circle at ${startX / window.innerWidth * 100}% ${startY / window.innerHeight * 100}%, transparent ${size - border}px, rgba(0, 0, 0, 0.5) ${size}px`;
        spotlight.classList.add('spotlight--active');

        const position = {x: startX, y: startY};

        anime({
            targets: position,
            keyframes: [
                {x: isDesktop ? (el.offsetWidth + size) : el.offsetWidth, y: startY },
                {x: isDesktop ? (el.offsetWidth + size) : el.offsetWidth, y: targetY },
                {x: targetX, y: targetY },
            ],
            easing: 'easeOutQuint',
            duration: 4000,
            delay: 1000,
            round: 10,
            update: () => spotlight.style.backgroundImage = `radial-gradient(circle at ${position.x / window.innerWidth * 100}% ${position.y / window.innerHeight * 100}%, transparent ${size - border}px, rgba(0, 0, 0, 0.5) ${size}px`,
            complete: () => spotlight.classList.remove('spotlight--active'),
        });
    }

    const elBoundingBox = el.getBoundingClientRect();

    // If element is within viewport then init spotlight right away (see https://stackoverflow.com/a/7557433/3628692)
    if (elBoundingBox.top >= 0 && elBoundingBox.left >= 0 && elBoundingBox.bottom <= (window.innerHeight || document.documentElement.clientHeight) && elBoundingBox.right <= (window.innerWidth || document.documentElement.clientWidth)) {
        initSpotlight();
    } else {
        // Other wise wait until it appears in the viewport after a scroll
        let scrollTimeout;

        // Init the spotlight only when the scroll is finished
        const scrollListener = () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = window.setTimeout(() => {
                window.removeEventListener('scroll', scrollListener);
                initSpotlight();
            }, 100);
        }

        window.addEventListener('scroll', scrollListener);

        // Start scrolling
        el.scrollIntoView({ behavior: 'smooth' });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // Shuffle all slider elements before initializing
    document.querySelectorAll('.swiper-wrapper').forEach((wrapper) => {
        [...wrapper.children].forEach(el => el.classList.add('swiper-slide'));
        for (let i = wrapper.children.length; i >= 0; i--) {
            wrapper.appendChild(wrapper.children[Math.random() * i | 0]);
        }
    })

    const constructionPagesOverview = document.querySelector('.construction-pages--overview');

    // Construction pages slider – overview
    if (constructionPagesOverview) {
        const board = new ProjectBoard(constructionPagesOverview.querySelector('[data-project-board]'));

        const onSlideChange = (instance) => {
            board.setTitle(instance.slideItems[instance.index].dataset.title);
            board.setRows(JSON.parse(instance.slideItems[instance.index].dataset.rows));
        }

        const slider = tns({
            container: '.construction-pages__slider .swiper-wrapper',
            items: 1,
            slideBy: 'page',
            prevButton: document.querySelector('.construction-pages--overview .swiper-button-prev'),
            nextButton: document.querySelector('.construction-pages--overview .swiper-button-next'),
            navContainer: document.querySelector('.construction-pages--overview .swiper-pagination'),
            mouseDrag: true,
            loop: false,
            autoHeight: false,
            onInit: onSlideChange,
        });
        slider.events.on('indexChanged', onSlideChange);
    }

    // Initialize project boards before construction pages slider
    [...document.querySelectorAll('[data-project-board]')].forEach(el => {
        new ProjectBoard(el, true);
    });

    // Construction pages slider – related
    if (document.querySelector('.construction-pages--related .construction-pages__slider')) {
        tns({
            container: '.construction-pages--related .construction-pages__slider .swiper-wrapper',
            items: 1,
            slideBy: 'page',
            controls: false,
            navPosition: 'bottom',
            mouseDrag: true,
            loop: false,
            autoHeight: true,
            responsive: {
                992: {
                    items: 2,
                    gutter: 30,
                },
                1200: {
                    items: 3,
                    gutter: 30,
                },
            },
        });
    }

    // Team slider
    if (document.querySelector('.team')) {
        tns({
            container: '.team .swiper-wrapper',
            items: 1,
            slideBy: 'page',
            controls: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            loop: true,
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            responsive: {
                576: {
                    items: 2,
                    gutter: 30,
                },
                992: {
                    items: 3,
                    gutter: 30,
                    loop: false,
                },
                1200: {
                    items: 4,
                    gutter: 30,
                },
            },
        });
    }

    // Power up all links that scroll to boarding pass
    [...document.querySelectorAll('a[href*="#boarding-pass"]')].forEach(link => {
        link.addEventListener('click', scrollToBoardingPass);
    });
});

window.addEventListener('load', () => {
    // Scroll to boarding pass on window load
    setTimeout(scrollToBoardingPass, 1000);

    // Scroll to boarding pass on hash change event
    window.addEventListener('hashchange', scrollToBoardingPass);
});
