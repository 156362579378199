// Inspired by https://bl.ocks.org/veltman/d0abfb4e84e6dc0801ccf382b0f479d1
import 'waypoints/lib/noframework.waypoints';

const AVAILABLE_CHARACTERS = (document.documentElement.lang === 'de') ? ' ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ,-' : ' ABCDEFGHIJKLMNOPQRSTUVWXYZ,-';

export class ProjectBoard {
    constructor(el, disableTitleCursor = false) {
        this.el = el;
        this.titleEl = el.querySelector('[data-project-board-title]');
        this.rowsCount = 4;
        this.flapsCount = 15;

        // Initialize the board if hasn't been initialized yet
        if (!this.el.dataset.projectBoardInitialized) {
            this._init();

            const waypoint = new window.Waypoint({
                element: this.el,
                offset: window.innerHeight,
                handler: () => {
                    if (this.el.dataset.projectBoard) {
                        this.setRows(JSON.parse(this.el.dataset.projectBoard));
                    }

                    if (this.titleEl.dataset.projectBoardTitle) {
                        this.setTitle(this.titleEl.dataset.projectBoardTitle, disableTitleCursor);
                    }

                    waypoint.destroy();
                }
            });

            this.el.dataset.projectBoardInitialized = '1';
        }
    }

    setTitle(title, hideCursor = false) {
        if (!this.titleEl) {
            throw new Error('Title element not found!');
        }

        const timeout = Math.floor(2000 / title.length / 2);
        let intervalWrite;

        this.nextTitle = title;
        this.titleEl.classList.add('project-board__row--title-animating');

        if (hideCursor) {
            this.titleEl.classList.add('project-board__row--title-hide-cursor');
        }

        // Do not multiply title clearing animations
        if (!this.isTitleClearing) {
            clearInterval(intervalWrite);

            this.onTitleClear = new Promise(resolve => {
                this.isTitleClearing = true;

                if (this.titleEl.innerText.length === 0) {
                    this.isTitleClearing = false;
                    resolve();
                    return;
                }

                // Remove the current text
                const interval = setInterval(() => {
                    if (this.titleEl.innerText.length === 0) {
                        this.isTitleClearing = false;
                        clearInterval(interval);
                        resolve();
                    } else {
                        this.titleEl.innerText = this.titleEl.innerText.substring(0, this.titleEl.innerText.length - 1);
                    }
                }, timeout);
            });
        }

        this.onTitleClear.then(() => {
            let index = 0;
            this.titleEl.classList.remove('project-board__row--title-animating');

            // Write new text after a short delay (~2 blinks)
            setTimeout(() => {
                this.titleEl.classList.add('project-board__row--title-animating');

                intervalWrite = setInterval(() => {
                    if (title !== this.nextTitle) {
                        clearInterval(intervalWrite);
                        return;
                    }

                    this.titleEl.innerText = this.titleEl.innerText + title.charAt(index++);

                    if (title.length === index) {
                        clearInterval(intervalWrite);
                        this.titleEl.classList.remove('project-board__row--title-animating');
                    }
                }, timeout);
            }, hideCursor ? 0 : 2000);
        });
    }

    setRows(rows) {
        for (let i = 0; i < this.rowsCount; i++) {
            let counter = 0;

            for (let j = 0; j < this.flapsCount; j++) {
                const character = rows[i].charAt(j) || ' ';
                const flap = this.rows[i][j];

                clearTimeout(flap.timer);

                // Stop the existing flapping animation and start the next one
                if (flap.isFlapping) {
                    flap.stop = () => { this._flapCharacter(flap, character.toUpperCase()) };
                } else {
                    // Start flapping immediately if the flap is idle
                    flap.timer = setTimeout(() => {
                        this._flapCharacter(flap, character.toUpperCase());
                    }, ++counter * (50 * (i + 1)))
                }
            }
        }
    }

    _flapCharacter(flap, targetCharacter) {
        const sourceCharacter = flap.character || ' ';

        // Use space as target character if it's not available
        if (!AVAILABLE_CHARACTERS.includes(targetCharacter)) {
            targetCharacter = ' ';
        }

        if (sourceCharacter === targetCharacter) {
            return;
        }

        const sourceIndex = AVAILABLE_CHARACTERS.indexOf(sourceCharacter);
        const targetIndex = AVAILABLE_CHARACTERS.indexOf(targetCharacter);
        const characters = [];

        // Get the available characters
        if (targetIndex > sourceIndex) {
            characters.push(...AVAILABLE_CHARACTERS.substring(sourceIndex, targetIndex + 1).split(''));
        } else {
            characters.push(...(AVAILABLE_CHARACTERS.substring(sourceIndex) + AVAILABLE_CHARACTERS.substring(0, targetIndex + 1)).split(''));
        }

        characters.shift();
        flap.character = targetCharacter;

        let nextCharacter = characters.shift();
        let counter = 0;

        function onAnimationIteration() {
            counter++;
            flap.flip.el.classList.remove('project-board__flap-half--back', 'project-board__flap-half--front');

            const flapHalf = counter % 2;

            // Finish the animation
            if (!flapHalf && (flap.stop || nextCharacter === targetCharacter)) {
                flap.character = nextCharacter;
                flap.isFlapping = false;

                // Remove the event
                flap.flip.el.removeEventListener('animationend', onAnimationIteration);

                flap.bottom.text.innerText = nextCharacter;

                flap.flap.classList.remove('project-board__flap-animated');

                // Resolve the promise
                if (flap.stop) {
                    flap.stop();
                    flap.stop = null;
                }
                return;
            }

            if (flapHalf) {
                flap.flip.text.innerText = nextCharacter;
                flap.flip.el.classList.remove('project-board__flap-half--front')
                flap.flip.el.classList.add('project-board__flap-half--back')
            } else {
                flap.bottom.text.innerText = nextCharacter;

                nextCharacter = characters.shift();

                flap.flip.el.classList.remove('project-board__flap-half--back')
                flap.flip.el.classList.add('project-board__flap-half--front')
                flap.top.text.innerText = nextCharacter;
            }
        }

        // Update on every animation iteration
        flap.flip.el.addEventListener('animationend', onAnimationIteration);

        // Start the animation
        flap.isFlapping = true;
        flap.flap.classList.add('project-board__flap-animated');
        flap.top.text.innerText = nextCharacter;
        flap.flip.text.innerText = nextCharacter;
        flap.flip.el.classList.add('project-board__flap-half--front');
    }

    _init() {
        this.rows = [];

        for (let i = 0; i < this.rowsCount; i++) {
            const row = this._createRow();
            const flaps = [];

            for (let j = 0; j < this.flapsCount; j++) {
                const flap = this._createFlap();
                row.appendChild(flap.flap);
                flaps.push(flap);
            }

            this.el.appendChild(row);
            this.rows.push(flaps);
        }
    }

    _createFlap() {
        const flap = document.createElement('div');
        flap.className = 'project-board__flap';

        const inner = document.createElement('div');
        inner.className = 'project-board__flap-inner';
        flap.appendChild(inner);

        const top = this._createFlipHalf('project-board__flap-half project-board__flap-half--next');
        inner.appendChild(top.el);

        const bottom = this._createFlipHalf('project-board__flap-half project-board__flap-half--prev');
        inner.appendChild(bottom.el);

        const flip = this._createFlipHalf('project-board__flap-half');
        inner.appendChild(flip.el);

        return {
            flap,
            top,
            bottom,
            flip,
        };
    }

    _createFlipHalf(className) {
        const el = document.createElement('div');
        el.className = className;

        const text = document.createElement('div');
        text.className = 'project-board__flap-half-text';
        el.appendChild(text);

        return { el, text };
    }

    _createRow() {
        const row = document.createElement('div');
        row.className = 'project-board__row';

        return row;
    }
}
